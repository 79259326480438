import { CurrencyKey } from './currency'

export const PROD_HOSTNAME = 'dextoro.com'

export const EXTERNAL_LINKS = {
	Trading: {
		Legacy: 'https://dextoro.com/',
		PerpsV1: 'https://dextoro.com/',
		DexAG: 'https://dex.ag/',
		Uniswap: 'https://uniswap.exchange/',
		OneInch: `https://1inch.exchange/`,
		OneInchApi: {
			ethereum: 'https://api.1inch.io/v5.0/1/',
			optimism: 'https://api.1inch.io/v5.0/10/',
		},
		OneInchLink: (from: CurrencyKey, to: CurrencyKey) => `https://1inch.exchange/#/${from}/${to}`,
		OptimismTokenBridge: 'https://gateway.optimism.io',
	},
	Options: {
		Trade: 'https://dextoro.com/',
	},
	Synthetix: {
		Home: 'https://www.synthetix.io',
		Litepaper: 'https://docs.synthetix.io/litepaper/',
	},
	Social: {
		Twitter: 'https://twitter.com/DexToro_com',
		Mirror: 'https://mirror.xyz/dextoro.eth',
		Discord: 'https://discord.gg/dextoro',
		GitHub: 'https://github.com/DexToroProtocol',
		telegram: 'https://t.me/dextoroexchange',
		Youtube: 'https://www.youtube.com/@dextoro',
		Instagram: 'https://www.instagram.com/dextoro/',
		Blog: 'https://blog.dextoro.com/',
	},
	TokenLists: {
		Zapper: 'https://zapper.fi/api/token-list',
	},
	Docs: {
		SmartWallet: 'https://docs.dextoro.com/trade/smart-wallets',
		Documentation: 'http://docs.dextoro.com/',
		DocsRoot: 'https://dune.com/0xvitalii/dextoro-stats',
		DMatrix: 'https://dune.com/0xvitalii/dtoro-token',
		Status: 'https://dextoro.instatus.com/',
		FeeReclamation: 'https://docs.dextoro.com/resources/fee-reclamation',
		HowToTrade: 'https://docs.dextoro.com/products/futures',
		Governance: 'http://docs.dextoro.com/dtoro-token/staking-dtoro',
		DaoRoles: 'https://gov.dextoro.com/sections/2',
		HowToUse: 'https://docs.dextoro.com/onboard/how-to-start-using-dextoro',
		Perpetuals: 'https://docs.dextoro.com/products/futures',
		Spot: 'https://docs.dextoro.com/products/swaps ',
		DevDao: 'https://docs.dextoro.com/dao/contribute/devdao-contribute',
		MarketingDao: 'https://gov.dextoro.com/sections/2/#marketingdao-grants-council-trial',
		Faq: 'https://docs.dextoro.com/',
		CrossMarginFaq: 'https://docs.dextoro.com/trade/smart-wallets', // Link to change
		Staking: 'https://docs.dextoro.com/dtoro-token/staking-dtoro',
		TradingRewardsV2: 'https://mirror.xyz/dextoro.eth',
		RewardsGuide: 'https://docs.dextoro.com/dtoro-token/trading-rewards',
		Metrics: 'https://docs.dextoro.com/resources/metrics',
	},
	Optimism: {
		Home: 'https://optimism.io/',
		TxScan: 'https://optimistic.etherscan.io/tx/',
		AddressScan: 'https://optimistic.etherscan.io/address/',
	},
	Trade: {
		PerpsV2: 'https://trade.dextoro.com/market/?accountType=smart_margin&asset=sETH',
		Spot: 'https://trade.dextoro.com/exchange/',
		V1: 'https://v1.trade.dextoro.com/dashboard',
	},
	Governance: {
		Kips: 'https://gov.trade.dextoro.com/all-kips',
		Vote: 'https://snapshot.org/#/dextoro.eth',
	},
	Competition: {
		LearnMore: 'https://mirror.xyz/dextoro.eth/s_PO64SxvuwDHz9fdHebsYeQAOOc73D3bL2q4nC6LvU',
	},
	Referrals: {
		Referral: 'https://trade.dextoro.com/referrals?code=',
	},
	Staking: {
		TotalStaked:
			'https://optimistic.etherscan.io/address/0x5b53C6B382D416EA74CD8A19D48719983e3a28B4',
		EscrowVesting:
			'https://optimistic.etherscan.io/address/0x4F69163C3E052dD3bEeb5fab1CfC0fDBe736D255',
		CirculatingSupply: 'https://coinmarketcap.com/currencies/dextoro/',
	},
	Profile: {
		U: 'https://trade.dextoro.com/u',
	},
}
