import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { wei } from '@synthetixio/wei'
import { formatDollars, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import OptimisimIcon from 'assets/svg/app/optimism.svg'

import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import Button from 'components/Button'
import { FlexDivRowCentered } from 'components/layout/flex'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import { depositSmartMargin } from 'state/futures/smartMargin/actions'
import { selectSubmittingSmartMarginTx } from 'state/futures/selectors'
import { selectSusdBalance } from 'state/balances/selectors'

import { MaxButton } from '../Trade/DepositWithdrawCrossMargin'
// import darkTheme from 'styles/theme/colors/dark'

interface DepositProps {
	setTab: (tab: 'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading') => void
}

const Deposit: FC<DepositProps> = ({ setTab }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const sUSDWalletBalance = useAppSelector(selectSusdBalance)
	const depositing = useAppSelector(selectSubmittingSmartMarginTx)

	const [amount, setAmount] = useState('')

	const handleMaxBalance = () => {
		setAmount(Number(truncateNumbers(sUSDWalletBalance, 5)).toString())
	}

	const handleDeposit = async () => {
		if (amount === '' || amount === '0') {
			notifyError('Please input margin deposit amount')
		} else {
			dispatch(depositSmartMargin(wei(amount)))
		}
	}

	return (
		<Container>
			<Body fontSize={20}>{t('futures.market.manage.deposit.title')}</Body>
			<Body fontSize={14} color="secondary">
				{t('futures.market.manage.deposit.text')}
			</Body>
			<BalanceContainer>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceText>
					{t('dashboard.oneclick.fund.balance')}
					<span onClick={handleMaxBalance}>{formatDollars(sUSDWalletBalance)} sUSD</span>
				</BalanceText>
			</BalanceContainer>
			<InputContainer
				placeholder="0.00"
				value={amount.toString()}
				onChange={(_, v) => setAmount(v !== '' ? v : '')}
				right={
					<MaxButton onClick={handleMaxBalance}>
						{t('futures.market.trade.margin.modal.max')}
					</MaxButton>
				}
			/>
			<Alert color="secondary">{t('futures.market.manage.deposit.alert')}</Alert>
			<OptimismButton onClick={() => setTab('bridge')}>
				Click here to bridge margin to
				<BaseIconWrapper>
					<OptimisimIcon /> Optimism
				</BaseIconWrapper>
			</OptimismButton>
			<Button
				onClick={handleDeposit}
				disabled={
					depositing ||
					wei(amount === '' ? 0 : amount).eq(0) ||
					wei(amount === '' ? 0 : amount).gt(sUSDWalletBalance)
				}
			>
				{depositing ? <Loader /> : t('futures.market.manage.deposit.title')}
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`
// background-color: ${darkTheme.red};
const OptimismButton = styled(Button)`
	color: ${({ theme }) => theme.colors.selectedTheme.red};
	border: none;
	background: #ff53531a;
	text-transform: none;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	font-size: 13px;
	&:hover {
		background: #ff53531a;
	}
`
const BaseIconWrapper = styled.div`
	display: flex;
	align-items: center;
`
const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
`

const BalanceText = styled.p`
	font-size: 13px;
	justify-content: space-between;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
		cursor: pointer;
	}
`

const InputContainer = styled(NumericInput)`
	margin-top: -10px;
	height: 45px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
`

const Alert = styled(Body)`
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	width: 100%;
	height: 35px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 8px 0;
`

export default Deposit
