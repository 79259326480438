import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useState, FC, useCallback, useMemo } from 'react'
import { truncateAddress, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import media from 'styles/media'

import LinkIcon from 'assets/svg/app/link-new.svg'
import { Body } from 'components/Text'
import Button from 'components/Button'
import Select, { DropdownIndicator, IndicatorSeparator } from 'components/Select'
import { EXTERNAL_LINKS } from 'constants/links'
import ProgressSteps from 'components/ProgressSteps'
import { notifyError } from 'components/ErrorNotifier'
import { DEFAULT_TX_FEE } from 'constants/defaults'
import { durationOptions } from 'constants/oneclick'
import { Timer } from 'components/Timer'
import Loader from 'components/Loader'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectSessionActivated,
	selectSessionValidUntil,
	selectSmartAccountAddress,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import {
	setSessionActivated,
	setSessionValidUntil,
	setUsingBiconomy,
} from 'state/oneClickTrading/reducer'
import { activeSession } from 'state/oneClickTrading/helper'
import { monitorTransaction } from 'contexts/RelayerContext'
import { FetchStatus } from 'state/types'
import useWindowSize from 'hooks/useWindowSize'

import { ManageProps, OneClickTradingTabs } from './types'

const ManageSession: FC<ManageProps> = ({ onChangeTab, isModal, isMenu }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const sessionValidUntil = useAppSelector(selectSessionValidUntil)
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const isLowBalance = wei(smartAccountBalance).lt(DEFAULT_TX_FEE)
	const estimatedTxCount = Math.floor(wei(smartAccountBalance).div(DEFAULT_TX_FEE).toNumber())

	const [duration, setDuration] = useState({ label: '1H', value: 1 })
	const [processing, setProcessing] = useState(false)

	const createSession = async () => {
		setProcessing(true)
		try {
			if (smartMarginAccount) {
				const hash = await activeSession(
					smartMarginAccount,
					isSessionActivated ? 0 : duration.value
				)
				monitorTransaction({
					txHash: hash,
					onTxConfirmed: () => {
						isSessionActivated
							? dispatch(setSessionActivated(false))
							: dispatch(setSessionActivated(true))
						isSessionActivated
							? dispatch(setUsingBiconomy(false))
							: dispatch(setUsingBiconomy(true))
						const now = Math.floor(Date.now() / 1000)
						// if session already actived, inactive session by setting validUntil now
						const validUntil = isSessionActivated ? now : now + duration.value * 3600
						dispatch(setSessionValidUntil(validUntil))
					},
					onTxFailed: () => {
						dispatch({ type: 'oneClick/activeSession', payload: FetchStatus.Error })
					},
				})
			}
		} catch (err: any) {
			const error: any = {
				message: 'Error: One-Click Trading balance is low. Please, fund your wallet and try again!',
			}
			if (err.code !== 4001) notifyError('Transaction failed', error)
			throw err
		} finally {
			setProcessing(false)
		}
	}

	const handleSession = async () => {
		await createSession()
	}

	const getText = () => {
		if (isSessionActivated) {
			if (processing) return <Loader />
			if (isModal || isMenu) return t('dashboard.oneclick.manage.stop-session')
			return t('dashboard.oneclick.manage.disable')
		}
		if (processing) return <Loader />
		if (isModal || isMenu) return t('dashboard.oneclick.manage.start-session')
		return t('dashboard.oneclick.manage.enable')
	}

	const formatOptionLabel = useCallback(
		(option: { label: string; value: number }) => (
			<SelectLabel onClick={() => setDuration(option)}>
				<Body fontSize={14}>{option.label}</Body>
			</SelectLabel>
		),
		[]
	)

	return (
		<Container>
			<Body fontSize={16}>
				{isMenu
					? t('dashboard.oneclick.manage.manage-session')
					: t('dashboard.oneclick.manage.current-session')}
			</Body>
			{isModal && (
				<>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.manage.duration')}
					</Body>
					<DurationContainer>
						{durationOptions.map((dur, index) => (
							<div
								key={index}
								className={`${duration.value === dur.value ? 'active' : ''}`}
								onClick={() => setDuration(dur)}
							>
								{dur.label}
							</div>
						))}
					</DurationContainer>
				</>
			)}
			{isMenu ? (
				<MenuCard>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.status')}
						</Body>
						<StatusDiv fontSize={14} active={isSessionActivated}>
							<div></div> <span>{isSessionActivated ? 'Active' : 'Inactive'}</span>
						</StatusDiv>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.address')}
						</Body>
						<Link
							href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
							target="_blank"
						>
							<StyledBody fontSize={14}>
								{truncateAddress(smartAccountAddress)}
								<LinkIcon />
							</StyledBody>
						</Link>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.eth-balance')}
						</Body>
						<StyledBody fontSize={14}>
							{Number(truncateNumbers(smartAccountBalance, 5))}
							{!isModal && !isMenu && (
								<LinkButton fontSize={11} onClick={onChangeTab?.(OneClickTradingTabs.Fund)}>
									{t('dashboard.oneclick.manage.fund')}
								</LinkButton>
							)}
						</StyledBody>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.expires-in')}
						</Body>
						<Body fontSize={14}>
							<Timer validUntil={sessionValidUntil} />
						</Body>
					</div>
				</MenuCard>
			) : (
				<Card isModal={isModal} mobile={isMobile}>
					{!isModal && (
						<>
							<div>
								<Body fontSize={13} color="secondary">
									{t('dashboard.oneclick.manage.status')}
								</Body>
								<StatusDiv fontSize={14} active={isSessionActivated}>
									<div></div> <span>{isSessionActivated ? 'Active' : 'Inactive'}</span>
								</StatusDiv>
							</div>
							<div>
								<Body fontSize={13} color="secondary">
									{t('dashboard.oneclick.manage.duration')}
								</Body>
								<DurationSelect
									formatOptionLabel={formatOptionLabel}
									controlHeight={27}
									options={durationOptions}
									optionPadding="2px"
									value={duration}
									menuWidth={75}
									components={{ IndicatorSeparator, DropdownIndicator }}
									isSearchable={false}
									variant="flat"
									isDisabled={isSessionActivated}
								/>
							</div>
						</>
					)}
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.est-tx-nums')}
						</Body>
						<Body fontSize={14}>{estimatedTxCount}</Body>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.address')}
						</Body>
						<Link
							href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
							target="_blank"
						>
							<StyledBody fontSize={14}>
								{truncateAddress(smartAccountAddress)}
								<LinkIcon />
							</StyledBody>
						</Link>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.eth-balance')}
						</Body>
						<StyledBody fontSize={14}>
							{Number(truncateNumbers(smartAccountBalance, 5))}
							{!isModal && !isMenu && (
								<LinkButton fontSize={11} onClick={onChangeTab?.(OneClickTradingTabs.Fund)}>
									{t('dashboard.oneclick.manage.fund')}
								</LinkButton>
							)}
						</StyledBody>
					</div>
					<div>
						<Body fontSize={13} color="secondary">
							{t('dashboard.oneclick.manage.expires-in')}
						</Body>
						<Body fontSize={14}>
							<Timer validUntil={sessionValidUntil} />
						</Body>
					</div>
				</Card>
			)}
			{isModal && <ProgressSteps step={3} totalSteps={3} complete={false} />}
			{isMenu && (
				<DurationContainer>
					{durationOptions.map((dur, index) => (
						<div
							key={index}
							className={`${duration.value === dur.value ? 'active' : ''}`}
							onClick={() => setDuration(dur)}
						>
							{dur.label}
						</div>
					))}
				</DurationContainer>
			)}
			{isMenu && isLowBalance && (
				<LowBalance color="secondary">{t('dashboard.oneclick.manage.low-balance')}</LowBalance>
			)}
			{!(isMenu && isLowBalance) && (
				<Button onClick={handleSession} disabled={processing}>
					{getText()}
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const DurationContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 4px;

	& > div {
		width: 100%;
		color: ${(props) => props.theme.colors.selectedTheme.primary};
		font-size: 13px;
		border-right: ${(props) => props.theme.colors.selectedTheme.border};
		padding: 2px;
		text-align: center;
		cursor: pointer;
		user-select: none;
	}

	& > div.active {
		background: ${(props) => props.theme.colors.selectedTheme.table.fill};
		border-radius: 4px;
	}
`

const Card = styled.div<{ isModal?: boolean; mobile?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	padding: ${(props) => (props.mobile ? '16px' : '24px')};
	border-radius: 8px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	row-gap: ${(props) => (props.mobile ? '20px' : '24px')};

	& > div {
		width: ${(props) => (props.isModal || props.mobile ? '50%' : '33.33%')};
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`

const DurationSelect = styled(Select)`
	.react-select__control {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		border-radius: 10px;
		border-width: 0px;
		width: 75px;
		position: absolute;
	}

	.react-select__menu,
	.react-select__menu-list {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		border-width: 0px;
		position: absolute;
		left: 0;
		top: 10px;
		width: 80px;
	}

	.react-select__value-container,
	.react-select__indicator {
		padding: 0;
	}

	.react-select__single-value > div > div {
		font-size: 12px;
		color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	}

	.react-select__dropdown-indicator {
		margin-right: 10px;
	}
`

const SelectLabel = styled.div`
	padding-left: 10px;
`

const StyledBody = styled(Body)`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 16px;
		height: 16px;
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.primary};
		}
	}
`

const LinkButton = styled(Button)`
	height: 16px;
	padding: 8px;
	border-radius: 8px;
`

const MenuCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 400px;

	${media.lessThan('md')`
	width: 100%;
	`}

	& > div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`

const LowBalance = styled(Body)`
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	width: 100%;
	height: 45px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
`

const StatusDiv = styled(Body)<{ active: boolean }>`
	display: flex;
	align-items: center;
	gap: 5px;

	& > div {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: ${({ theme, active }) =>
			active ? theme.colors.selectedTheme.green : theme.colors.selectedTheme.red};
	}
`

export default ManageSession
