import { FC, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LightOnIcon from 'assets/svg/app/light-on.svg'
import BaseModal from 'components/BaseModal'
import { FlexDiv, FlexDivCol } from 'components/layout/flex'
import { Body } from 'components/Text'

import { setOpenModal } from 'state/app/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { showDefaultTab } from 'state/app/selectors'
import useWindowSize from 'hooks/useWindowSize'

import Deposit from './Deposit'
import Withdraw from './Withdraw'
import OneClickTrading from './OneClickTrading'
import Bridge from './Bridge'

const ManageAccount: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const defaultTab = useAppSelector(showDefaultTab)
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const [tab, setTab] = useState<'deposit' | 'withdraw' | 'bridge' | 'oneclick-trading'>(defaultTab)

	const onClose = () => dispatch(setOpenModal(null))

	return (
		<StyledBaseModal onDismiss={onClose} isOpen={true} title={t('futures.market.manage.title')}>
			{isMobile ? (
				<FlexDivCol>
					<MobileContent>
						{tab === 'deposit' && <Deposit setTab={setTab} />}
						{tab === 'withdraw' && <Withdraw />}
						{tab === 'bridge' && <Bridge />}
						{tab === 'oneclick-trading' && <OneClickTrading />}
					</MobileContent>
					<MobileTabs>
						<Tab active={tab === 'deposit'} onClick={() => setTab('deposit')}>
							Deposit
						</Tab>
						<Tab active={tab === 'withdraw'} onClick={() => setTab('withdraw')}>
							Withdraw
						</Tab>
						<Tab active={tab === 'bridge'} onClick={() => setTab('bridge')}>
							Bridge & Swap
						</Tab>
						<Tab active={tab === 'oneclick-trading'} onClick={() => setTab('oneclick-trading')}>
							<LightOnIcon />
							One-Click Trading
						</Tab>
					</MobileTabs>
				</FlexDivCol>
			) : (
				<FlexDiv>
					<Tabs>
						<Tab active={tab === 'deposit'} onClick={() => setTab('deposit')}>
							Deposit
						</Tab>
						<Tab active={tab === 'withdraw'} onClick={() => setTab('withdraw')}>
							Withdraw
						</Tab>
						<Tab active={tab === 'bridge'} onClick={() => setTab('bridge')}>
							Bridge & Swap
						</Tab>
						<Tab active={tab === 'oneclick-trading'} onClick={() => setTab('oneclick-trading')}>
							<LightOnIcon />
							One-Click Trading
						</Tab>
					</Tabs>
					<Content>
						{tab === 'deposit' && <Deposit setTab={setTab} />}
						{tab === 'withdraw' && <Withdraw />}
						{tab === 'bridge' && <Bridge />}
						{tab === 'oneclick-trading' && <OneClickTrading />}
					</Content>
				</FlexDiv>
			)}
		</StyledBaseModal>
	)
}

export default ManageAccount

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		width: 750px;
	}
`

const Tabs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 10px 20px 0 0;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	width: 270px;
`

const Tab = styled(Body)<{ active: boolean }>`
	font-size: 15px;
	background: ${({ theme, active }) =>
		active ? theme.colors.selectedTheme.button.fill : 'transparent'};
	color: ${({ theme, active }) =>
		active ? theme.colors.selectedTheme.button.text.primary : theme.colors.selectedTheme.gray};
	border-radius: 8px;
	padding: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
	white-space: nowrap;

	svg {
		width: 18px;
	}

	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
`

const Content = styled.div`
	padding: 8px 16px 16px 32px;
	width: 100%;
	min-height: 472px;
`

const MobileContent = styled.div`
	min-height: 472px;
`

const MobileTabs = styled.div`
	display: flex;
	gap: 8px;
	overflow-x: scroll;
	margin-top: 8px;
`
