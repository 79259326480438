import { RootState } from 'state/store'

import { unserializeGasPrice } from './helpers'

export const selectShowModal = (state: RootState) => state.app.showModal

export const showDefaultTab = (state: RootState) => state.app.defaultTab

export const selectShowPositionModal = (state: RootState) => state.app.showPositionModal

export const selectGasSpeed = (state: RootState) => state.app.gasSpeed

export const selectGasPrice = (state: RootState) => unserializeGasPrice(state.app.gasPrice)

export const selectTransaction = (state: RootState) => state.app.transaction

export const selectAckedOrdersWarning = (state: RootState) => state.app.acknowledgedOrdersWarning

export const selectShowBanner = (state: RootState) => state.app.showBanner

export const selectShowWalkthrough = (state: RootState) => state.app.showWalkthrough

export const selectSettings = (state: RootState) => state.app.settings

export const selectMobileTradePanel = (state: RootState) => state.app.showMobileTradePanel
