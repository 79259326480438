import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { wei } from '@synthetixio/wei'
import { formatDollars, truncateNumbers } from '@dextoroprotocol/sdk/utils'

import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import Button from 'components/Button'
import { FlexDivRowCentered } from 'components/layout/flex'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectIdleAccountMargin } from 'state/futures/smartMargin/selectors'
import { withdrawSmartMargin } from 'state/futures/smartMargin/actions'
import { selectSubmittingSmartMarginTx } from 'state/futures/selectors'

import ManageKeeperBalance from '../TradeSmartMargin/ManageKeeperBalance'
import { MaxButton } from '../Trade/DepositWithdrawCrossMargin'

const Withdraw: FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const sUSDMarginBalance = useAppSelector(selectIdleAccountMargin)
	const withdrawing = useAppSelector(selectSubmittingSmartMarginTx)
	const [amount, setAmount] = useState('')

	const handleMaxBalance = () => {
		setAmount(Number(truncateNumbers(sUSDMarginBalance, 5)).toString())
	}

	const handleWithdraw = async () => {
		if (amount === '' || amount === '0') {
			notifyError('Please input margin withdraw amount')
		} else if (wei(sUSDMarginBalance).eq('0')) {
			notifyError('You have no margin amount')
		} else {
			dispatch(withdrawSmartMargin(wei(amount)))
		}
	}

	return (
		<Container>
			<Body fontSize={20}>{t('futures.market.manage.withdraw.title')}</Body>
			<Body fontSize={14} color="secondary">
				{t('futures.market.manage.withdraw.text')}
			</Body>
			<BalanceContainer>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceText>
					<BalanceInfo>
						{t('futures.market.manage.withdraw.balance')}
						<span onClick={handleMaxBalance}>{formatDollars(sUSDMarginBalance)} sUSD</span>
					</BalanceInfo>
				</BalanceText>
			</BalanceContainer>
			<InputContainer
				placeholder="0.00"
				value={amount.toString()}
				onChange={(_, v) => setAmount(v !== '' ? v : '')}
				right={
					<MaxButton onClick={handleMaxBalance}>
						{t('futures.market.trade.margin.modal.max')}
					</MaxButton>
				}
			/>
			<Button
				onClick={handleWithdraw}
				disabled={
					withdrawing ||
					wei(amount === '' ? 0 : amount).eq(0) ||
					wei(amount === '' ? 0 : amount).gt(sUSDMarginBalance)
				}
			>
				{withdrawing ? <Loader /> : t('futures.market.manage.withdraw.withdraw')}
			</Button>
			<ManageKeeperBalance defaultType="withdraw" />
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
`

const BalanceText = styled.p`
	font-size: 13px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
		cursor: pointer;
	}
`

const BalanceInfo = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 4px;
`

const InputContainer = styled(NumericInput)`
	margin-top: -10px;
	height: 45px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
`

export default Withdraw
