import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useState } from 'react'
import { truncateAddress, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'

import LinkIcon from 'assets/svg/app/link-new.svg'
import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import { FlexDivRowCentered } from 'components/layout/flex'
import Button from 'components/Button'
import { notifyError } from 'components/ErrorNotifier'
import Loader from 'components/Loader'
import { MaxButton } from 'sections/futures/Trade/DepositWithdrawCrossMargin'
import { EXTERNAL_LINKS } from 'constants/links'
import { DEFAULT_TX_FEE } from 'constants/defaults'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectSmartAccountAddress,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { withdraw } from 'state/oneClickTrading/helper'
import { monitorTransaction } from 'contexts/RelayerContext'
import { FetchStatus } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { setSmartAccountBalance } from 'state/oneClickTrading/reducer'

const Withdraw = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const walletAddress = useAppSelector(selectWallet)

	const [amount, setAmount] = useState('')
	const [processing, setProcessing] = useState(false)

	const handleMaxBalance = () => {
		const balance = wei(smartAccountBalance).sub(DEFAULT_TX_FEE)
		setAmount(balance.gt(0) ? Number(truncateNumbers(balance, 5)).toString() : '')
	}

	const handleWithdraw = async () => {
		setProcessing(true)
		try {
			if (!walletAddress) return
			const hash = await withdraw(walletAddress, amount)
			monitorTransaction({
				txHash: hash!,
				onTxConfirmed: () => {
					dispatch(setSmartAccountBalance(wei(smartAccountBalance).sub(amount).toString()))
				},
				onTxFailed: () => {
					dispatch({ type: 'oneClick/withdraw', payload: FetchStatus.Error })
				},
			})
		} catch (err: any) {
			const error: any = {
				message: 'Error: One-Click Trading balance is low. Please, fund your wallet and try again!',
			}
			if (err.code !== 4001) notifyError('Transaction failed', error)
			throw err
		} finally {
			setProcessing(false)
		}
	}

	return (
		<Container>
			<Body fontSize={14} color="secondary">
				{t('dashboard.oneclick.withdraw.text')}
			</Body>
			<Card>
				<div>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.fund.address')}
					</Body>
					<Link
						href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
						target="_blank"
					>
						<StyledBody fontSize={14}>
							{truncateAddress(smartAccountAddress)}
							<LinkIcon />
						</StyledBody>
					</Link>
				</div>
				<div>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.fund.eth-balance')}
					</Body>
					<Body fontSize={14}>{Number(truncateNumbers(smartAccountBalance, 5))} ETH</Body>
				</div>
			</Card>
			<BalanceContainer>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceText>
					{t('dashboard.oneclick.withdraw.balance')}
					<span onClick={handleMaxBalance}>
						{Number(truncateNumbers(smartAccountBalance, 5))}
					</span>{' '}
					ETH
				</BalanceText>
			</BalanceContainer>
			<InputContainer
				placeholder="0.00"
				value={amount}
				onChange={(_, v) => setAmount(v)}
				right={
					<MaxButton onClick={handleMaxBalance}>
						{t('futures.market.trade.margin.modal.max')}
					</MaxButton>
				}
			/>
			<Button
				onClick={handleWithdraw}
				disabled={processing || wei(amount === '' ? 0 : amount).lte(0)}
			>
				{processing ? <Loader /> : t('dashboard.oneclick.withdraw.withdraw')}
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Card = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 24px;
	border-radius: 8px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	row-gap: 24px;

	& > div {
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`

const StyledBody = styled(Body)`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 16px;
		height: 16px;
		margin-bottom: 2px;
		path {
			stroke: currentColor;
		}
	}
`

const InputContainer = styled(NumericInput)`
	margin-top: -10px;
	height: 45px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
`

const BalanceText = styled.p`
	font-size: 13px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
		cursor: pointer;
	}
`

export default Withdraw
