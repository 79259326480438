import { formatDollars } from '@dextoroprotocol/sdk/utils'
import dynamic from 'next/dynamic'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Error from 'components/ErrorView'
import { FlexDivRowCentered } from 'components/layout/flex'
import Spacer from 'components/Spacer'
import { Body } from 'components/Text'

import { selectTransaction } from 'state/app/selectors'
import { selectSusdBalance } from 'state/balances/selectors'
import { useAppSelector } from 'state/hooks'

const SocketBridge = dynamic(() => import('../../../components/SocketBridge'), {
	ssr: false,
})

const Bridge: React.FC = () => {
	const { t } = useTranslation()

	const transactionState = useAppSelector(selectTransaction)
	const susdBal = useAppSelector(selectSusdBalance)

	return (
		<div>
			<Body fontSize={20}>{t('futures.market.trade.margin.modal.bridge.title')}</Body>
			<Spacer height={10} />
			<SocketBridge />
			<Spacer height={20} />
			<BalanceContainer>
				<BalanceText>{t('futures.market.trade.margin.modal.balance')}:</BalanceText>
				<BalanceText>
					<span>{formatDollars(susdBal)}</span> sUSD
				</BalanceText>
			</BalanceContainer>
			{transactionState?.error && (
				<Error
					containerStyle={{ margin: '24px 0 0 0' }}
					message={transactionState.error}
					formatter="revert"
				/>
			)}
		</div>
	)
}

const BalanceContainer = styled(FlexDivRowCentered)`
	margin-bottom: 8px;
	p {
		margin: 0;
	}
`

const BalanceText = styled.p<{ $gold?: boolean }>`
	color: ${(props) =>
		props.$gold ? props.theme.colors.selectedTheme.yellow : props.theme.colors.selectedTheme.gray};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	}
	font-feature-settings: 'zero' 0;
`

export default Bridge
