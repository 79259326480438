import { DialogOverlay, DialogContent } from '@reach/dialog'
import { FC, memo, ReactNode } from 'react'
import { Rnd, Props } from 'react-rnd'
import styled, { css } from 'styled-components'

import CrossIcon from 'assets/svg/app/cross.svg'
import Card, { CardHeader, CardBody } from 'components/Card'
import { zIndex } from 'constants/ui'
import { resetButtonCSS } from 'styles/common'
import media from 'styles/media'

export type BaseModalProps = {
	title: ReactNode
	isOpen?: boolean
	onDismiss: () => void
	children: ReactNode
	showCross?: boolean
	lowercase?: boolean
	rndProps?: Props
	hasBackdropFilter?: boolean
	isCentered?: boolean
}

type ModalContentWrapperProps = {
	children: ReactNode
	rndProps?: Props
}

const ModalContentWrapper: FC<ModalContentWrapperProps> = memo(({ children, rndProps }) => {
	if (rndProps?.disableDragging) {
		return <>{children}</>
	} else {
		return <Rnd {...rndProps}>{children}</Rnd>
	}
})

export const BaseModal: FC<BaseModalProps> = memo(
	({
		onDismiss,
		title,
		children,
		isOpen,
		showCross = true,
		lowercase,
		rndProps = { disableDragging: true, enableResizing: false },
		hasBackdropFilter = false,
		isCentered = false,
		...rest
	}) => (
		<StyledDialogOverlay
			onDismiss={onDismiss}
			isOpen={isOpen}
			{...rest}
			hasBackdropFilter={hasBackdropFilter}
			isCentered={isCentered}
		>
			<StyledDialogContent aria-label="modal">
				<ModalContentWrapper rndProps={rndProps}>
					<StyledCard className="card">
						<StyledCardHeader lowercase={lowercase} noBorder className="card-header" tc={title}>
							{title}
							{showCross && (
								<DismissButton onClick={onDismiss}>
									<StyledCrossIcon width={20} height={20} />
								</DismissButton>
							)}
						</StyledCardHeader>
						<StyledCardBody className="card-body">{children}</StyledCardBody>
					</StyledCard>
				</ModalContentWrapper>
			</StyledDialogContent>
		</StyledDialogOverlay>
	)
)

const StyledDialogOverlay = styled(DialogOverlay)<{
	hasBackdropFilter: boolean
	isCentered: boolean
}>`
	z-index: ${zIndex.DIALOG_OVERLAY};

	${media.lessThan('md')`
	z-index: ${zIndex.MOBILE_FOOTER};
	overflow: scroll;
	display: flex;
	align-items: flex-end;
	`}

	backdrop-filter: ${(props) => (props.hasBackdropFilter ? 'blur(6px)' : 'none')};
	${(props) =>
		props.isCentered &&
		css`
			display: flex;
			align-items: center;
		`}
`

const StyledDialogContent = styled(DialogContent)`
	padding: 0;
	border: 0;
	background: none;

	${media.lessThan('md')`
		&&& {
			/*width: 80%;*/
			display: flex;
			justify-content: center;
			align-items: center;
		}
	`}

	${media.lessThan('sm')`
		&&& {
			width: 100%;
			margin: 0 auto;
		}
	`}
`

const StyledCard = styled(Card)`
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	border-radius: 10px;
	position: relative;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	${media.lessThan('md')`
		&&& {
			width: 100%;
			border-radius: 10px 10px 0 0;
			bottom: 2px;
		}
	`}
`

const StyledCardHeader = styled(CardHeader)<{ tc: ReactNode }>`
	height: 45px;
	font-size: 20px;
	height: 64px;
	font-size: 20px;
	padding: 20px;
	margin-bottom: 10px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	font-family: ${(props) => props.theme.fonts.regular};
	padding: 20px;
	${(props) =>
		props.tc === 'DexToro Terms of Service' &&
		css`
			justify-content: center;
		`}
`

const StyledCardBody = styled(CardBody)`
	overflow-y: scroll;
	padding: 0 20px;
	padding-bottom: 20px;
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	border-radius: 10px;
`

const DismissButton = styled.button`
	${resetButtonCSS};
	position: absolute;
	right: 20px;
	width: 32px;
	height: 32px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHoverFoot};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
`

const StyledCrossIcon = styled(CrossIcon)`
	path {
		fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
`

export default BaseModal
