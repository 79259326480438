import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useState, useMemo } from 'react'
import { truncateAddress, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import { ethers } from 'ethers'

import LinkIcon from 'assets/svg/app/link-new.svg'
import { Body } from 'components/Text'
import NumericInput from 'components/Input/NumericInput'
import { FlexDivRowCentered } from 'components/layout/flex'
import Button from 'components/Button'
import ProgressSteps from 'components/ProgressSteps'
import Loader from 'components/Loader'
import { EXTERNAL_LINKS } from 'constants/links'
import { DEFAULT_TX_FEE } from 'constants/defaults'
import { txFees } from 'constants/oneclick'
import { MaxButton } from 'sections/futures/Trade/DepositWithdrawCrossMargin'

import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectSmartAccountAddress,
	selectSmartAccountBalance,
	selectWalletBalance,
} from 'state/oneClickTrading/selectors'
import Connector from 'containers/Connector'
import logError from 'utils/logError'
import { setSmartAccountBalance, setWalletBalance } from 'state/oneClickTrading/reducer'
import useWindowSize from 'hooks/useWindowSize'

import { FundProps } from './types'

const Fund: React.FC<FundProps> = ({ isModal }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const walletBalance = useAppSelector(selectWalletBalance)
	const { signer, provider } = Connector.useContainer()
	const { deviceType } = useWindowSize()

	const { isMobile } = useMemo(() => {
		const isMobile = deviceType === 'mobile'
		return { isMobile }
	}, [deviceType])

	const [amount, setAmount] = useState('')
	const [txCount, setTxCount] = useState(0)
	const [processing, setProcessing] = useState(false)

	const handleTxCount = ({ count, eth }: { count: number; eth: number }) => {
		setTxCount(count)
		setAmount(eth.toString())
	}

	const handleMaxBalance = () => {
		const balance = wei(walletBalance).sub(DEFAULT_TX_FEE)
		setAmount(balance.gt(0) ? Number(truncateNumbers(balance, 5)).toString() : '')
	}

	const handleDeposit = async () => {
		setProcessing(true)
		try {
			const tx = await signer?.sendTransaction({
				to: smartAccountAddress,
				value: ethers.utils.parseEther(amount),
			})
			if (tx) {
				const txReceipt = await provider.waitForTransaction(tx.hash)
				if (txReceipt) {
					setProcessing(false)
					dispatch(setSmartAccountBalance(wei(smartAccountBalance).add(amount)))
					dispatch(setWalletBalance(wei(walletBalance).sub(amount)))
				}
			}
		} catch (e) {
			logError(e)
			setProcessing(false)
		}
	}

	return (
		<Container>
			<Body fontSize={14} color="secondary">
				{t('dashboard.oneclick.fund.text')}
			</Body>
			<Card mobile={isMobile}>
				<div>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.fund.address')}
					</Body>
					<Link
						href={`${EXTERNAL_LINKS.Optimism.AddressScan}${smartAccountAddress}`}
						target="_blank"
					>
						<StyledBody fontSize={14}>
							{truncateAddress(smartAccountAddress)}
							<LinkIcon />
						</StyledBody>
					</Link>
				</div>
				<div>
					<Body fontSize={13} color="secondary">
						{t('dashboard.oneclick.fund.eth-balance')}
					</Body>
					<Body fontSize={14}>{Number(truncateNumbers(smartAccountBalance, 5))} ETH</Body>
				</div>
			</Card>
			<BalanceContainer>
				<BalanceText>{t('dashboard.oneclick.fund.amount')}</BalanceText>
				<BalanceText>
					{t('dashboard.oneclick.fund.balance')}
					<span onClick={handleMaxBalance}>{Number(truncateNumbers(walletBalance, 5))}</span> ETH
				</BalanceText>
			</BalanceContainer>
			<InputContainer
				placeholder="0.00"
				value={amount}
				onChange={(_, v) => setAmount(v !== '' ? v : '')}
				right={
					<MaxButton onClick={handleMaxBalance}>
						{t('futures.market.trade.margin.modal.max')}
					</MaxButton>
				}
			/>
			<CardTxContainer>
				{txFees.map((tx, index) => (
					<CardTx
						key={index}
						isActive={txCount === tx.count}
						mobile={isMobile}
						onClick={() => handleTxCount(tx)}
					>
						<Body fontSize={14} color={txCount === tx.count ? 'primary' : 'secondary'}>
							{tx.count} Txs
						</Body>
						<Body fontSize={13} color="secondary">
							{tx.eth} ETH
						</Body>
					</CardTx>
				))}
			</CardTxContainer>
			{isModal && <ProgressSteps step={2} totalSteps={3} complete={false} />}
			<Button
				onClick={handleDeposit}
				disabled={
					wei(amount === '' ? 0 : amount).gt(walletBalance) ||
					wei(amount === '' ? 0 : amount).eq(0) ||
					processing
				}
			>
				{processing ? <Loader /> : t('dashboard.oneclick.fund.deposit')}
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Card = styled.div<{ mobile: boolean }>`
	display: flex;
	flex-wrap: wrap;
	padding: ${({ mobile }) => (mobile ? '16px' : '24px')};
	border-radius: 8px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	row-gap: 24px;

	& > div {
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`

const StyledBody = styled(Body)`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		width: 16px;
		height: 16px;
		margin-bottom: 2px;
		path {
			stroke: currentColor;
		}
	}
`

const InputContainer = styled(NumericInput)`
	margin-top: -10px;
	height: 45px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
`

const BalanceContainer = styled(FlexDivRowCentered)`
	p {
		margin: 0;
	}
`

const BalanceText = styled.p`
	font-size: 13px;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
		cursor: pointer;
	}
`

const CardTxContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const CardTx = styled.div<{ isActive: boolean; mobile: boolean }>`
	width: calc(25% - 10px);
	border: ${(props) =>
		props.isActive ? '1px solid #6966ff' : props.theme.colors.selectedTheme.border};
	border-radius: 4px;
	text-align: center;
	padding: ${({ mobile }) => (mobile ? '8px' : '12px')};
	cursor: pointer;
	user-select: none;
`

export default Fund
