import {
	TransactionStatus,
	FuturesMarketKey,
	OperationalStatus,
	GasPrice,
} from '@dextoroprotocol/sdk/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { notifyError } from 'components/ErrorNotifier'
import { isUserDeniedError } from 'utils/formatters/error'

import {
	AppState,
	FuturesPositionModalType,
	ModalType,
	Transaction,
	DefaultTab,
	ShowWalkthrough,
	Settings,
	MobileTradePanel,
} from './types'

export const APP_INITIAL_STATE: AppState = {
	showModal: undefined,
	gasPrice: {
		baseFeePerGas: '0', // Note that this is used for estimating price and should not be included in the transaction
		maxPriorityFeePerGas: '0',
		maxFeePerGas: '0',
		gasPrice: '0',
	},
	gasSpeed: 'fast',
	synthetixOnMaintenance: false,
	dextoroStatus: {
		status: OperationalStatus.FullyOperational,
		message: '',
		lastUpdatedAt: undefined,
	},
	acknowledgedOrdersWarning: false,
	showBanner: true,
	defaultTab: 'deposit',
	showWalkthrough: 'show',
	settings: false,
	showMobileTradePanel: false,
}

const appSlice = createSlice({
	name: 'app',
	initialState: APP_INITIAL_STATE,
	reducers: {
		setOpenModal: (state, action: PayloadAction<ModalType>) => {
			if (action.payload) {
				state.showPositionModal = null
			}
			state.showModal = action.payload
		},
		setDefaultTab: (state, action: PayloadAction<DefaultTab>) => {
			state.defaultTab = action.payload
		},
		setShowPositionModal: (
			state,
			action: PayloadAction<{ type: FuturesPositionModalType; marketKey: FuturesMarketKey } | null>
		) => {
			if (action.payload) {
				state.showModal = null
			}
			state.showPositionModal = action.payload
		},
		setGasPrice: (state, action: PayloadAction<GasPrice<string>>) => {
			state.gasPrice = action.payload
		},
		setTransaction: (state, action: PayloadAction<Transaction | undefined>) => {
			state.transaction = action.payload
		},
		updateTransactionStatus: (state, action: PayloadAction<TransactionStatus>) => {
			if (state.transaction) {
				state.transaction.status = action.payload
			}
		},
		updateTransactionHash: (state, action: PayloadAction<string>) => {
			if (state.transaction) {
				state.transaction.hash = action.payload
			}
		},
		handleTransactionError: (state, action: PayloadAction<string>) => {
			if (isUserDeniedError(action.payload)) {
				state.transaction = undefined
			} else {
				notifyError('Transaction failed', new Error(action.payload))
				if (state.transaction) {
					state.transaction.status = TransactionStatus.Failed
					state.transaction.error = action.payload
				}
			}
		},
		setAcknowledgedOrdersWarning: (state, action: PayloadAction<boolean>) => {
			state.acknowledgedOrdersWarning = action.payload
		},
		setShowBanner: (state, action: PayloadAction<boolean>) => {
			state.showBanner = action.payload
		},
		setShowWalkthrough: (state, action: PayloadAction<ShowWalkthrough>) => {
			state.showWalkthrough = action.payload
		},
		enableSettings: (state, action: PayloadAction<Settings>) => {
			state.settings = action.payload
		},
		showMobileTradePanel: (state, action: PayloadAction<MobileTradePanel>) => {
			state.showMobileTradePanel = action.payload
		},
	},
})

export const {
	setOpenModal,
	setDefaultTab,
	setShowPositionModal,
	setGasPrice,
	setTransaction,
	handleTransactionError,
	updateTransactionStatus,
	updateTransactionHash,
	setAcknowledgedOrdersWarning,
	setShowBanner,
	setShowWalkthrough,
	enableSettings,
	showMobileTradePanel,
} = appSlice.actions

export default appSlice.reducer
