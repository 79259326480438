export enum OneClickTradingTabs {
	Manage = 'manage',
	Fund = 'fund',
	Withdraw = 'withdraw',
}

export type ManageProps = {
	onChangeTab?: (tab: OneClickTradingTabs) => any
	isModal?: boolean
	isMenu?: boolean
}

export type ManageActivateProps = {
	isModal?: boolean
	onDismiss?: () => void
}

export type FundProps = {
	isModal?: boolean
}
